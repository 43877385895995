import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-680f6d81")
const _hoisted_1 = { class: "p-grid" }
const _hoisted_2 = { class: "p-grid" }
const _hoisted_3 = {
  class: "rank-title p-col-12",
  style: {"padding-top":"16px","padding-bottom":"16px"}
}
const _hoisted_4 = {
  key: 0,
  class: "others-title"
}
const _hoisted_5 = {
  key: 1,
  class: "others-title"
}
const _hoisted_6 = { class: "others-title" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BrainSpinner = _resolveComponent("BrainSpinner")!
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (!_ctx.teachers)
    ? (_openBlock(), _createBlock(_component_BrainSpinner, { key: 0 }))
    : (_openBlock(), _createBlock(_component_DataTable, {
        key: 1,
        responsiveLayout: "scroll",
        value: _ctx.row
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Column, { header: "Rango" }, {
            body: _withCtx(({data}) => [
              _createElementVNode("div", _hoisted_1, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data.ranks, (rank) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: rank,
                    class: "p-col"
                  }, [
                    _createElementVNode("div", _hoisted_2, [
                      _createElementVNode("div", _hoisted_3, _toDisplayString(rank.rank), 1),
                      _createElementVNode("div", {
                        class: _normalizeClass([[rank.rank == 0 ? '' :rank.rank < 6 ? 'p-tag-red-brain' : rank.rank < 9 ? 'p-tag-yellow-brain' : 'p-tag-light-green-brain'], "score p-col-12"])
                      }, _toDisplayString(rank.value), 3)
                    ])
                  ]))
                }), 128))
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_Column, {
            header: "Response Rate",
            style: {"vertical-align":"baseline"}
          }, {
            body: _withCtx(({data}) => [
              (_ctx.totalStudents != 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(data.responseRate.toFixed(1)) + "% ", 1))
                : (_openBlock(), _createElementBlock("div", _hoisted_5, " No disponible "))
            ]),
            _: 1
          }),
          _createVNode(_component_Column, {
            header: "Respuestas",
            style: {"vertical-align":"baseline"}
          }, {
            body: _withCtx(({data}) => [
              _createElementVNode("div", _hoisted_6, _toDisplayString(data.responses), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["value"]))
}