
import { computed, defineComponent, onMounted, PropType, Ref, ref, watch } from 'vue'

import { ChartDataItem } from '@/models/components/charts/ChartData'
import { getCertificatesByCourseId } from '@/api/course.api'
import ClassRatingChartByDate from '@/components/courses/charts/ClassRatingChartByDate.vue'
import NPSCommentBox from '@/components/courses/boxes/NPSCommentBox.vue'
import { CourseFeedbackAPI } from '@/api/feedbacks.api'
import { CourseFeedbackByPerson } from '@/models/course-feedbacks/interfaces/CourseFeedBackByPerson'
import { ClassFeedbackSummary } from '@/models/course-feedbacks/summaries/classes/ClassFeedbackSummary'
import { Course } from '@/models/course/classes/Course'
import { Student } from '@/models/course/builder/student.builder'
import { CommentsNPSInfo } from '@/models/course-feedbacks/summaries/interfaces/CommentSummary'
import { StaffMember } from '@/models/course/builder/staffMember.builder'
import NPSScoreTable from '@/components/courses/tables/NPSScoreTable.vue'
import NPSRankTable from '@/components/courses/tables/NPSRankTable.vue'
import { StudentAPI } from '@/api/students.api'

export default defineComponent({
  components: {
    ClassRatingChartByDate,
    NPSCommentBox,
    NPSScoreTable,
    NPSRankTable
  },
  props: {
    course: {
      type: Course,
      required: true
    },
    students: {
      type: Array as PropType<Student[]>,
      required: true
    },
    summaries: {
      type: Array as PropType<ClassFeedbackSummary[]>,
      required: true
    },
    staff: {
      type: Array as PropType<StaffMember[]>,
      required: false
    },
    staffIsLoading: {
      type: Boolean,
      required: false
    }
  },
  setup (props) {
    const course = computed(() => props.course)
    const summaries = computed(() => props.summaries)
    const numberOfActivesStudents = computed(() => props.students.filter(s => s?.user?.active).length)
    const loading = ref<boolean>(false)
    const loadingGraduates = ref<boolean>(true)
    const loadingTables = ref<boolean>(true)
    const loadingFeedbacks = ref<boolean>(true)
    const graduatesChartData = ref<ChartDataItem[]>([])
    const npsFeedbacks = ref<CourseFeedbackByPerson>(Object.create({}))
    const avgNPS = computed(() => summaries.value.find(summary => summary.npsSummary)?.npsSummary?.rating?.value)
    const NPSComments = ref<CommentsNPSInfo>()
    const studentsRef: Ref<Student[]> = ref([])
    const studentsIds: Ref<string[]> = ref([])
    watch(summaries, async () => {
      try {
        loadingGraduates.value = true
        loadingFeedbacks.value = true
        loadingTables.value = true
        npsFeedbacks.value = await CourseFeedbackAPI.findAllAfterClass(course.value?._id as string)
        NPSComments.value = await CourseFeedbackAPI.findNPSComments(course.value?._id as string)
      } catch (error) {
        console.error(error)
      } finally {
        loadingGraduates.value = false
        loadingFeedbacks.value = false
        loadingTables.value = false
      }
    })
    onMounted(async () => {
      try {
        loadingGraduates.value = true
        loadingFeedbacks.value = true
        loadingTables.value = true
        const certificates = await getCertificatesByCourseId((course.value?._id as string))
        graduatesChartData.value = [
          {
            label: 'Egresados',
            value: certificates.length
          },
          {
            label: 'No egresados',
            value: numberOfActivesStudents.value - certificates.length
          }
        ]
        npsFeedbacks.value = await CourseFeedbackAPI.findAllAfterClass(course.value?._id as string)
        NPSComments.value = await CourseFeedbackAPI.findNPSComments(course.value?._id as string)
        studentsRef.value = await StudentAPI.findAll(course.value?._id)
        studentsRef.value.map(s => studentsIds.value.push(s.user?.personId as string))
      } catch (error) {
        console.error(error)
      } finally {
        loadingGraduates.value = false
        loadingFeedbacks.value = false
        loadingTables.value = false
      }
    })

    return {
      loading,
      NPSComments,
      npsFeedbacks,
      graduatesChartData,
      loadingGraduates,
      loadingFeedbacks,
      loadingTables,
      avgNPS,
      studentsRef
    }
  }
})
