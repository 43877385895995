
import { defineComponent, PropType } from 'vue'
import { Detractors, Passives, Promoters } from '@/models/course-feedbacks/summaries/interfaces/CommentSummary'
export default defineComponent({
  props: {
    detractors: {
      type: Object as PropType<Detractors>,
      required: true
    },
    passives: {
      type: Object as PropType<Passives>,
      required: true
    },
    promoters: {
      type: Object as PropType<Promoters>,
      required: true
    },
    npsScore: {
      type: Number,
      required: true
    }
  },
  setup (props) {
    return {
      data: [
        {
          detractors: props.detractors,
          passives: props.passives,
          promoters: props.promoters
        }
      ]
    }
  }
})
