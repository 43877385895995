
import {
  computed,
  defineComponent,
  PropType,
  ref,
  watch
} from 'vue'
import { ChartData, ChartOptions } from 'chart.js'
import { Course } from '@/models/course/classes/Course'
import { CSSColors, FormatDates } from '@/models/enums'
import Chart from 'primevue/chart'
import { ClassFeedbackSummary } from '@/models/course-feedbacks/summaries/classes/ClassFeedbackSummary'

export default defineComponent({
  components: {
    Chart
  },
  props: {
    course: {
      type: Object as PropType<Course>,
      required: true
    },
    summaries: {
      type: Array as PropType<Array<ClassFeedbackSummary>>,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  setup (props) {
    const course = computed<Course>(() => props.course)
    const summaries = computed<ClassFeedbackSummary[]>(() => props.summaries)
    const isLoading = ref<boolean>(props.loading)
    const labels = ref<string[]>([])
    const contentRatingData = ref<number[]>([])
    const teacherRatingData = ref<number[]>([])
    const tutorRatingData = ref<number[]>([])
    const classRatings = ClassFeedbackSummary.parseToNPSClassSummaries(summaries.value)
    const existsData = computed<boolean>(() => [...contentRatingData.value, ...teacherRatingData.value, ...tutorRatingData.value].length > 0)
    const mappedClassRatings = classRatings.map(rating => ({
      ...rating,
      name: course.value.classes.find(c => c._id === rating.classId)?.name,
      day: course.value.classes.find(c => c._id === rating.classId)?.calendar.formatDate('start', FormatDates.LatinAmericaShort)
    }))
    mappedClassRatings.pop() // NOTE remove the last class because feedback always is NPS and values are null
    contentRatingData.value = mappedClassRatings.map(
      c => c.contentRating || 0
    )
    teacherRatingData.value = mappedClassRatings.map(
      c => c.teacherRating || 0
    )
    tutorRatingData.value = mappedClassRatings.map(c => c.tutorRating || 0)
    labels.value = mappedClassRatings.map(c => c.day as string)
    const classNames = computed<Map<number, string>>(() => {
      const mapper = new Map<number, string>()
      mappedClassRatings.map((c, i) => mapper.set(i, c.name as string))
      return mapper
    })
    const chartData = computed<ChartData>(() => ({
      labels: labels.value,
      datasets: [
        {
          label: 'Contenido',
          data: contentRatingData.value,
          dataIndex: course.value.classes.map(c => c._id),
          fill: false,
          borderColor: CSSColors.PurpleBrain
        },
        {
          label: 'Profesor',
          data: teacherRatingData.value,
          dataIndex: course.value.classes.map(c => c._id),
          fill: false,
          borderColor: CSSColors.RedBrain
        },
        {
          label: 'Tutores',
          data: tutorRatingData.value,
          dataIndex: course.value.classes.map(c => c._id),
          fill: false,
          borderColor: CSSColors.LightblueBrain
        }
      ]
    }))
    const chartOptions = ref<ChartOptions>({
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          align: 'end'
        },
        tooltip: {
          bodyAlign: 'left',
          bodySpacing: 10,
          callbacks: {
            label: (context) => {
              let label = context.dataset.label || ''
              if (label) {
                label += ': '
              }
              label += classNames.value?.get(context.dataIndex) + '     ' + context.raw
              return label
            }
          }
        }
      },
      scales: {
        x: {
          ticks: {
            color: '#495057'
          },
          grid: {
            color: '#ebedef'
          }
        },
        y: {
          max: 5.3,
          ticks: {
            color: '#495057',
            stepSize: 0.3
          },
          grid: {
            color: '#ebedef'
          }
        }
      }
    })
    watch(summaries, async () => {
      isLoading.value = true
      const classRatings = ClassFeedbackSummary.parseToNPSClassSummaries(summaries.value)
      let mappedClassRatings = classRatings.map(rating => ({
        ...rating,
        name: course.value.classes.find(c => c._id === rating.classId)?.name,
        day: course.value.classes.find(c => c._id === rating.classId)?.calendar.formatDate('start', FormatDates.LatinAmericaShort)
      }))
      if (mappedClassRatings?.length > 0) {
        mappedClassRatings = mappedClassRatings.filter(c => c.contentRating && c.teacherRating && c.tutorRating)
      }
      contentRatingData.value = mappedClassRatings.map(
        c => c.contentRating || 0
      )
      teacherRatingData.value = mappedClassRatings.map(
        c => c.teacherRating || 0
      )
      tutorRatingData.value = mappedClassRatings.map(c => c.tutorRating || 0)
      labels.value = mappedClassRatings.map(c => c.day as string)
      isLoading.value = false
    })
    return {
      chartData,
      chartOptions,
      isLoading,
      existsData,
      classNames
    }
  }
})
