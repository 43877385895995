
import { computed, defineComponent, PropType, ref, watch } from 'vue'

import { TabKey } from '@/models/components/tabs/enums'
import { CourseFeedbackByPerson } from '@/models/course-feedbacks/interfaces/CourseFeedBackByPerson'
import { PersonRoles } from '@/models/person/enums'

import NPSComments from '@/components/courses/tables/NPSComments.vue'
import { CommentNPS } from '@/models/course-feedbacks/summaries/interfaces/CommentSummary'

export default defineComponent({
  components: {
    NPSComments
  },
  props: {
    feedbacks: {
      type: Map as PropType<CourseFeedbackByPerson>,
      required: true
    },
    avgNPS: {
      type: Number,
      required: true
    },
    NPSComments: {
      type: Array as PropType<CommentNPS[]>,
      required: true
    }
  },
  setup (props) {
    const personFeedbacks = computed(() => props.feedbacks)
    const hasNoContentComments = ref<boolean>(true)
    const hasNoTeacherComments = ref<boolean>(true)
    const hasNoTutorComments = ref<boolean>(true)
    const hasNoGeneralComments = ref<boolean>(true)
    watch(personFeedbacks, () => {
      if (personFeedbacks.value) {
        console.log(personFeedbacks.value)
        hasNoContentComments.value = Array.from(personFeedbacks.value.values()).every(person => {
          return Array.from(person.feedbacks.values()).every(feedback => feedback.contentFeedback === null || feedback.contentFeedback?.comments.length === 0)
        })
        hasNoTeacherComments.value = Array.from(personFeedbacks.value.values()).every(person => {
          return Array.from(person.feedbacks.values()).every(feedback => feedback.entries === null || Array.from(feedback.entries?.values()).filter(entry => entry.role === PersonRoles.Teacher).every(teacherEntry => teacherEntry.comments.length === 0 || teacherEntry.comments === null))
        })
        hasNoTutorComments.value = Array.from(personFeedbacks.value.values()).every(person => {
          return Array.from(person.feedbacks.values()).every(feedback => feedback.entries === null || Array.from(feedback.entries?.values()).filter(entry => entry.role === PersonRoles.Tutor).every(tutorEntry => tutorEntry.comments.length === 0 || tutorEntry.comments === null))
        })
        hasNoGeneralComments.value = Array.from(personFeedbacks.value.values()).every(person => {
          return Array.from(person.feedbacks.values()).every(feedback => feedback.npsFeedback === null || feedback.npsFeedback?.comments.length === 0)
        })
      }
    })
    const tabsTable = computed(() => [
      { header: 'General', key: TabKey.General, content: 'Comentarios General' }
      /* { header: 'Profesor', key: TabKey.Teacher, content: 'Comentarios', disabled: true },
      { header: 'Tutor', key: TabKey.Tutor, content: 'Comentarios', disabled: true },
      { header: 'Contenido', key: TabKey.Content, content: 'Comentarios', disabled: true } */
    ])

    return {
      tabsTable,
      personFeedbacks,
      activeIndex: ref(0)
    }
  }
})
