
import { computed, defineComponent, PropType, ref } from 'vue'

import { ColumnFields } from '@/models/components/tables/enums'
import { TabKey } from '@/models/components/tabs/enums'
import { CourseFeedbackByPerson } from '@/models/course-feedbacks/interfaces/CourseFeedBackByPerson'
import { EvaluateSummary } from '@/models/course-feedbacks/enums'
import { Column } from '@/models/components/tables/Column'
import { FeedbackRow } from '@/models/components/tables/Row'

export default defineComponent({
  props: {
    feedbacks: {
      type: Map as PropType<CourseFeedbackByPerson>,
      required: true
    },
    tableKey: {
      type: String,
      required: true
    }
  },
  setup (props) {
    const feedbacks = computed(() => props.feedbacks)
    const tableKey = computed(() => props.tableKey)
    const feedbackClassId = computed(() => {
      if (feedbacks.value && feedbacks.value.size > 0) {
        return (Array.from((Array.from(feedbacks.value.values() || []))[0]?.feedbacks.keys()) || [])[0]
      }
      return undefined
    })
    const columns = ref<Array<Column>>([
      { field: ColumnFields.Student } as Column,
      { field: ColumnFields.Comments } as Column,
      { field: ColumnFields.Rating } as Column
    ])
    let rows = ref<Array<FeedbackRow>>([])
    rows = computed<Array<FeedbackRow>>(() => {
      const finalRows: Array<FeedbackRow> = []
      if (feedbacks.value !== undefined && feedbackClassId.value !== undefined) {
        for (const feedback of feedbacks.value.values()) {
          finalRows.push({
            personName: feedback.person.fullName,
            rating: feedback.feedbacks.get(feedbackClassId.value)?.npsFeedback?.score as number,
            comments: tableKey.value === TabKey.General as string
              ? feedback.feedbacks.get(feedbackClassId.value)?.npsFeedback?.comments.join('.') as string
              : tableKey.value === TabKey.Teacher as string
                ? feedback.feedbacks.get(feedbackClassId.value)?.entries.find(p => p.role === EvaluateSummary.teacher)?.comments.join('.') as string
                : tableKey.value === TabKey.Tutor as string
                  ? feedback.feedbacks.get(feedbackClassId.value)?.entries.find(p => p.role === EvaluateSummary.tutor)?.comments.join('.') as string
                  : tableKey.value === TabKey.Content as string
                    ? feedback.feedbacks.get(feedbackClassId.value)?.contentFeedback?.comments.join('.') as string
                    : ''
          })
        }
      }
      return finalRows
    })
    return {
      columns,
      rows,
      ColumnFields
    }
  }
})
