
import { NPSRank, Rank } from '@/models/components/tables/Row'
import { Student } from '@/models/course/builder/student.builder'
import { Person } from '@/models/person/classes/Person'
import { computed, defineComponent, onMounted, PropType, ref } from 'vue'

export default defineComponent({
  props: {
    students: {
      type: Array as PropType<Array<Student>>,
      required: true
    },
    responses: {
      type: Number,
      required: true
    },
    ranks: {
      type: Array as PropType<Rank[]>,
      required: true
    },
    total: {
      type: Number,
      required: true
    }
  },
  setup (props) {
    const studentsRef = computed<Student[] | undefined>(() => props.students)
    const studentsByTeacher = computed<Map<string, Student[]>>(() => Student.convertToStudentsByTeacher(studentsRef.value as Student[]))
    const getTeachers = async () => await Person.getPersonsById(Array.from(studentsByTeacher.value.keys()) as string[])
    const teachers = ref<Map<string, Person>>()
    let sum = 0
    const responseRate = ref<number>(0)
    const totalStudents = ref<number>(0)
    const row = ref<NPSRank[]>([])
    onMounted(async () => {
      try {
        teachers.value = await getTeachers()
        teachers.value.forEach(teacher => {
          sum += studentsByTeacher.value.get(teacher.id)!.length
        })
      } catch (error) {
        console.error(error)
      } finally {
        totalStudents.value = sum
        responseRate.value = (props.total / totalStudents.value) * 100
        row.value = [
          {
            responseRate: responseRate.value,
            responses: props.responses,
            ranks: props.ranks
          }
        ]
      }
    })
    return {
      totalStudents,
      row,
      teachers,
      responseRate
    }
  }
})
